// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-capabilities-asset-integrity-js": () => import("./../../../src/pages/capabilities/asset-integrity.js" /* webpackChunkName: "component---src-pages-capabilities-asset-integrity-js" */),
  "component---src-pages-capabilities-oil-and-gas-js": () => import("./../../../src/pages/capabilities/oil-and-gas.js" /* webpackChunkName: "component---src-pages-capabilities-oil-and-gas-js" */),
  "component---src-pages-capabilities-renewables-js": () => import("./../../../src/pages/capabilities/renewables.js" /* webpackChunkName: "component---src-pages-capabilities-renewables-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sheq-js": () => import("./../../../src/pages/sheq.js" /* webpackChunkName: "component---src-pages-sheq-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

